import Splide from '@splidejs/splide'

const splide = new Splide( '#js-hero-slider', {
    type   : 'slide',
    perPage: 1,
    rewind: true,
    speed: 300,
    autoplay: true,
    interval: 10000,
    pauseOnHover: false,
    pauseOnFocus: false,
    classes: {
        arrows: 'splide__arrows hidden md:block',
    },
} )

splide.mount();
